import { AiOutlineClose } from "react-icons/ai";
import { MdMinimize, MdMaximize } from "react-icons/md";
import { useEffect, useState } from "react";
import "../css/sys64.css";
import "../css/r03.css";
import Draggable from 'react-draggable';
import { Resizable, ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import Calculator from "./Calculator.tsx";
import TextEditor from "./TextEditor";

export const Application = ({ id, isOpen, onClose }) => {
	const [path, setPath] = useState("");
	const [title, setTitle] = useState("");
	const [icon, setIcon] = useState("");
	let onResize;
	onResize = (event, {node, size, handle}) => {
		setSize({width: size.width, height: size.height});
	};
	useEffect(() => {
		switch (id) {
			case 1:
				setPath("apps/perlinNoise/index.html");
				setTitle("/c/perlinNoise.html");
				setIcon("icons/iframe.png");
				break;
			case 2:
				setPath("apps/github/github.html");
				setTitle("/c/aboutme.html");
				setIcon("icons/iframe.png");
				break;
			case 3:
				setPath("apps/games/index.html");
				setTitle("/c/latenightsnack.unity");
				setIcon("icons/unity.png");
				break;
			case 4:
				setTitle("/c/calculator.exe");
				setIcon("icons/calculator.png");
				break;
			case 5:
				setTitle("/c/text editor.exe");
				setIcon("icons/iframe.png");
				break;
		}
	}, [])

	const [position, setPosition] = useState({ x: 0, y: 0 });
	const [size, setSize] = useState({ width: 400, height: 300 });
	const [offset, setOffset] = useState({ x: 0, y: 0 });
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [screenHeight, setScreenHeight] = useState(window.innerHeight);
	const [isMaximized, setIsMaximized] = useState(false);

	const setSizeOfApplication = () => {
		if (isMaximized) {
			setSize({ width: 400, height: 400 });
			setPosition({ x: screenWidth / 2, y: screenHeight / 2 })
			setIsMaximized(false)
		} else {
			setIsMaximized(true)
			setSize({ width: screenWidth, height: screenHeight });
			setPosition({ x: screenWidth / 2, y: screenHeight / 2 })
		}
	}

	if (isOpen && id !== 4 && id !== 5) {
		return (
			<Draggable handle=".window_head">
				<Resizable

					width={size.width}
					height={size.height}
					minConstraints={[200, 150]}
					maxConstraints={[screenWidth, screenHeight]}
					onResize={onResize}
					onResizeStop={(e, { offset }) => setOffset(offset)}
					resizeHandles={['e', 's','se',]}
				>
					<div role="dialog" className="window window-active title-bar" id="applicationWindow" data-window-id="0"
						 aria-labelledby="ui_window__title_0" data-js-resize-init="true"
						 style={{ top: position.y + (offset ? offset.y : 0), left: position.x + (offset ? offset.x : 0), width: size.width + 'px', height: size.height + 'px'}}>

						<header className="window_head title-bar" id={id}>
							<img className="window_head_icon"
								 src={icon} alt="Window Icon" />
							<span className="window_head_title ui_elipsis window-draggable noselect"
								  id="ui_window_title_0">{title}</span>
							<button><MdMinimize /></button>
							<button onClick={() => setSizeOfApplication()}><MdMaximize /></button>
							<button onClick={() => onClose()}><AiOutlineClose /></button>
						</header>

						<section className="window_body skin_inset_deep skin_light window_body-with_iframe menu_trigger window_body-flex">
							<iframe className="window_iframe" allowFullScreen="true" title="Application Content"
									src={path}></iframe>
						</section>
					</div>
				</Resizable>
			</Draggable>
		);
	}
	else if(isOpen && id === 4)
	{
		return(
			<Draggable handle=".window_head">
				<Resizable

					width={size.width}
					height={size.height}
					minConstraints={[200, 150]}
					maxConstraints={[screenWidth, screenHeight]}
					onResize={onResize}
					onResizeStop={(e, { offset }) => setOffset(offset)}
					resizeHandles={['e', 's','se',]}
				>
					<div role="dialog" className="window window-active title-bar" id="applicationWindow" data-window-id="0"
						 aria-labelledby="ui_window__title_0" data-js-resize-init="true"
						 style={{ top: position.y + (offset ? offset.y : 0), left: position.x + (offset ? offset.x : 0), width: size.width + 'px', height: size.height + 'px'}}>

						<header className="window_head title-bar" id={id}>
							<img className="window_head_icon"
								 src={icon} alt="Window Icon" />
							<span className="window_head_title ui_elipsis window-draggable noselect"
								  id="ui_window_title_0">{title}</span>
							<button><MdMinimize /></button>
							<button onClick={() => setSizeOfApplication()}><MdMaximize /></button>
							<button onClick={() => onClose()}><AiOutlineClose /></button>
						</header>

						<section className="window_body skin_inset_deep skin_light window_body-with_iframe menu_trigger window_body-flex">
							<Calculator/>
						</section>
					</div>
				</Resizable>
			</Draggable>
		)
	}

	else if(isOpen && id === 5)
	{
		return(
			<Draggable handle=".window_head">
				<Resizable

					width={size.width}
					height={size.height}
					minConstraints={[200, 150]}
					maxConstraints={[screenWidth, screenHeight]}
					onResize={onResize}
					onResizeStop={(e, { offset }) => setOffset(offset)}
					resizeHandles={['e', 's','se',]}
				>
					<div role="dialog" className="window window-active title-bar" id="applicationWindow" data-window-id="0"
						 aria-labelledby="ui_window__title_0" data-js-resize-init="true"
						 style={{ top: position.y + (offset ? offset.y : 0), left: position.x + (offset ? offset.x : 0), width: size.width + 'px', height: size.height + 'px'}}>

						<header className="window_head title-bar" id={id}>
							<img className="window_head_icon"
								 src={icon} alt="Window Icon" />
							<span className="window_head_title ui_elipsis window-draggable noselect"
								  id="ui_window_title_0">{title}</span>
							<button><MdMinimize /></button>
							<button onClick={() => setSizeOfApplication()}><MdMaximize /></button>
							<button onClick={() => onClose()}><AiOutlineClose /></button>
						</header>

						<section className="window_body skin_inset_deep skin_light window_body-with_iframe menu_trigger window_body-flex">
							<TextEditor/>
						</section>
					</div>
				</Resizable>
			</Draggable>
		)
	}
};
