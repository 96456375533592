import logo from './logo.svg';
import "./css/Calculator.css"
import './App.css';



import $ from 'jquery';

import {Application} from "./components/Application";
import {MainSite} from "./components/MainSite";
import {useEffect, useState} from "react";
import {LoadingScreen} from "./components/LoadingScreen";
import Calculator from "./components/Calculator.tsx";
import TextEditor from "./components/TextEditor";

const App = () =>
{
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const randomTime = Math.floor(Math.random() * 10000) + 3000; // Random time between 1 and 5 seconds

        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, randomTime);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    if(isLoading)
    {
        return (
            <LoadingScreen/>
        )
    }

    else
    {
        return (
            <div className="App">

                <MainSite/>

            </div>
        );
    }

}

export default App;
