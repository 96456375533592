import {useEffect, useState} from "react";

import "../css/sys64.css";
import "../css/r03.css";
import {Application} from "./Application";
export const MainSite = () => {
	const [time, setTime] = useState(new Date());

	const [openModalIds, setOpenModalIds] = useState([]);
	const [activeModalId, setActiveModalId] = useState(null);

	const handleDoubleClick = (id) => {
		if (!openModalIds.includes(id)) {
			setOpenModalIds([...openModalIds, id]);
			setActiveModalId(id);
		}
	};

	const handleCloseModal = (id) => {
		const updatedModalIds = openModalIds.filter((modalId) => modalId !== id);
		setOpenModalIds(updatedModalIds);
		if (activeModalId === id) {
			setActiveModalId(null);
		}
	};


	const getTitle = (id) => {
		switch (id) {
			case 1:
				return 'Perlin Noise';
			case 2:
				return 'about me';
			case 3:
				return 'Unity Game';
			case 4:
				return 'Calculator'
			case 5:
				return 'Text'
			default:
				return 'unknown app';
		}
	};
	const titles = openModalIds.map((modalId) => getTitle(modalId));

	useEffect(() => {
		const interval = setInterval(() => {
			setTime(new Date());
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<div id="s64_screen">
			<div id="nofx" className="ui_layout">
				<div className="fillspace skin_bg" id="s64_bg"></div>
				<section id="s64_desktop" className="noscroll">
					<div className="desktop_files">
						<div className="desktop_explorer desktop_explorer-workspace" id="ui_explorer_0" data-id="0">
							<div className="ui_icon" id="doorOSSys" onDoubleClick={() => handleDoubleClick(63)}>
								<img width="32" height="32" src="devices/computer.png"/>
								<p>DoorOS03</p>
							</div>
							<div className="ui_icon" id="perlinAppIcon" onDoubleClick={() => handleDoubleClick(1)} path={"apps/perlinNoise/index.html"} title={"Perlin Noise"}>
								<img width="32" height="32" src="devices/perlinnoise.png"/>
								<p>Perlin Noise</p>
							</div>
							<div className="ui_icon" id="aboutMeIcon" onDoubleClick={() => handleDoubleClick(2)}>
								<img width="32" height="32" src="devices/me.png"/>
								<p>About me</p>
							</div>
							<div className="ui_icon" id="gameIcon" onDoubleClick={() => handleDoubleClick(3)}>
								<img width="32" height="32" src="icons/unity.png"/>
								<p>A late Night Snack</p>
							</div>
							<div className="ui_icon" id="calculator" onDoubleClick={() => handleDoubleClick(4)}>
								<img width="32" height="32" src="icons/calculator.png"/>
								<p>Calculator</p>
							</div>
							<div className="ui_icon" id="textEditor" onDoubleClick={() => handleDoubleClick(5)}>
								<img width="32" height="32" src="icons/iframe.png"/>
								<p>Notepad</p>
							</div>
						</div>
					</div>
					{openModalIds.map((modalId) => (
						<Application key={modalId} id={modalId} isOpen={true} onClose={() => handleCloseModal(modalId)} />
					))}
				</section>
				<footer className="pa0 relative">
					<div className="combo_one skin_base skin_outset_deep" id="s64_taskbar">
						<button id="s64_start" className="menu_trigger">
							<img width="16" height="16" src="devices/door.png"/>
							<span><b>Start</b></span>
						</button>
						<div id="s64_dock" className="combo_one_main window_dock">
							{openModalIds.map((modalId, index) => (
								<button key={modalId} className="window_docked pressed" title={titles[index]}>
									<img className="window_docked_icon" src="icons/iframe.png"/>
										<span className="ui_window_docked__text ui_elipsis">{titles[index]}</span>
								</button>
							))}
						</div>
						<div id="s64_notif" className="skin_inset">
                <span id="s64_clock" title={time.toString()}
					  data-js-notif-id="0">{time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}</span>
						</div>
					</div>
				</footer>
			</div>
		</div>
	)
}