// @ts-ignore
import React from "react";
import "../../css/Calculator.css"

type Props = React.HTMLProps<HTMLButtonElement> &
    {
        buttonType: ButtonType
        label: string;
        position?: [x: number, y: number];
        width?: number;
        color?: string;

    }

export enum ButtonType
{
    Number,
    Operation
}

const Button: React.FC<Props> = ({buttonType, children, label, position, width, color, onClick}) =>
{
    const styles: React.CSSProperties = {

    };

    if(position)
    {
        styles.gridColumnStart = position[0] + 1;
        styles.gridRowStart = position[1] + 1;
    }
    if(width)
    {
        styles.gridColumnEnd = `span ${width}`;
    }

    if(color)
    {
        styles.color = color;
    }
    return(
        <button onClick={onClick} style={styles} className="StylizedButton unstyled">{label}</button>
    )
}

export default Button;