// @ts-ignore
import React, {useState} from "react";
import Button, {ButtonType} from './CalculatorRel/Button';

import "../css/Calculator.css"
import Display from "./CalculatorRel/Display";
import Calc, {CalcInput, InputType, OperatorType} from "./CalculatorRel/calc";


const Calculator: React.FC<{}> = () =>
{
    const [inputs, setInputs] = useState<Array<CalcInput>>([])
    const state = Calc.getState(inputs);


    const appendInput = (input: CalcInput): void =>
    {
        setInputs((prev) => [...prev, input])
    }

    const handleBackspace = ():void =>
    {
        setInputs((prev) => [...prev, ]);
    }

    const handleAllClear = () => setInputs([])

    const handleNumerical = (value: number) => () =>
    {
        console.log(value)
        appendInput({type: InputType.Numerical, value})
    }

    const handleOperation = (operator:OperatorType) => () =>
    {
        appendInput({type: InputType.Operator, operator})
        console.log(operator);
    }


    const [displayValue, setDisplayValue] = useState('');
    const [hasDotPressed, setHasDotPressed] = useState(false);

    return(
        <div className="CalculatorSkin">

            <div className="grid">
                <Display label={state.displayValue}/>
                <Button label="CE" onClick={handleAllClear} buttonType={ButtonType.Operation} position={[0, 2]} width={5} color="#fd1111"/>

                <Button label="/" buttonType={ButtonType.Operation} position={[3, 3]} color="#fd1111" onClick={handleOperation(OperatorType.Divide)}/>
                <Button label="+" buttonType={ButtonType.Operation} position={[3, 6]} color="#fd1111" onClick={handleOperation(OperatorType.Add)}/>
                <Button label="=" buttonType={ButtonType.Operation} position={[4, 6]} color="#fd1111" onClick={handleOperation(OperatorType.Equals)}/>
                <Button label="1/x" buttonType={ButtonType.Operation} position={[4, 5]} onClick={handleOperation(OperatorType.DividedByOne)}/>
                <Button label="%" buttonType={ButtonType.Operation} position={[4, 4]} onClick={handleOperation(OperatorType.Modulo)}/>
                <Button label="sqrt" buttonType={ButtonType.Operation} position={[4, 3]} onClick={handleOperation(OperatorType.Sqrt)}/>
                <Button label="-" buttonType={ButtonType.Operation} position={[3, 5]} color="#fd1111" onClick={handleOperation(OperatorType.Subtract)}/>
                <Button label="*" buttonType={ButtonType.Operation} position={[3, 4]} color="#fd1111" onClick={handleOperation(OperatorType.Multiply)}/>
                <Button label="7" buttonType={ButtonType.Number} position={[0, 3]} onClick={handleNumerical(7)}/>
                <Button label="8" buttonType={ButtonType.Number} position={[1, 3]} onClick={handleNumerical(8)}/>
                <Button label="9" buttonType={ButtonType.Number} position={[2, 3]} onClick={handleNumerical(9)}/>
                <Button label="4" buttonType={ButtonType.Number} position={[0, 4]} onClick={handleNumerical(4)}/>
                <Button label="5" buttonType={ButtonType.Number} position={[1, 4]} onClick={handleNumerical(5)}/>
                <Button label="6" buttonType={ButtonType.Number} position={[2, 4]} onClick={handleNumerical(6)}/>
                <Button label="1" buttonType={ButtonType.Number} position={[0, 5]} onClick={handleNumerical(1)}/>
                <Button label="2" buttonType={ButtonType.Number} position={[1, 5]} onClick={handleNumerical(2)}/>
                <Button label="3" buttonType={ButtonType.Number} position={[2, 5]} onClick={handleNumerical(3)}/>
                <Button label="0" width={3} buttonType={ButtonType.Number} position={[0, 6]} onClick={handleNumerical(0)}/>

            </div>
        </div>
    )
}

export default Calculator;