// @ts-ignore
import React from "react";
import "../../css/Calculator.css"
import {ButtonType} from "./Button";

type Props =
    {
        label: number;
    }

const Display: React.FC<Props> = ({label}) =>
{

    return(
        <p className="CalculatorDisplay unstyled">{label}</p>
    )
}

export default Display;