import "../css/loading.css";

export const LoadingScreen = () => {
	return (
		<div className="loadingWindow">
			<div className="logo">
				<img src="icons/me.jpg"/>
				<p className="top">Richard Larsson Ivarsson</p>
				<p className="mid">DoorOS<span>03</span></p>
				<p className="bottom">Professional</p>
			</div>
			<div className="container">
				<div className="box"></div>
				<div className="box"></div>
				<div className="box"></div>
			</div>
		</div>
	)
}